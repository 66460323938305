const DISTRICTS = [
    'Adansi North District',
    'Adansi South District',
    'Afigya-Kwabre District',
    'Ahafo Ano North District',
    'Ahafo Ano South District',
    'Amansie Central District',
    'Amansie West District',
    'Asante Akim Central Municipal District',
    'Asante Akim North District',
    'Asante Akim South District',
    'Asokore Mampong Municipal District',
    'Atwima Kwanwoma District',
    'Atwima Mponua District',
    'Atwima Nwabiagya District',
    'Bekwai Municipal District',
    'Bosome Freho District',
    'Botsomtwe District',
    'Ejisu-Juaben Municipal District',
    'Ejura - Sekyedumase District',
    'Kumasi Metropolitan District',
    'Kwabre East District',
    'Mampong Municipal District',
    'Obuasi Municipal District',
    'Offinso North District',
    'Offinso South Municipal District',
    'Sekyere Afram Plains District',
    'Sekyere Central District',
    'Sekyere East District',
    'Sekyere Kumawu District',
    'Sekyere South District',
    'Asunafo North Municipal District',
    'Asunafo South District',
    'Asutifi North District',
    'Asutifi South District',
    'Atebubu-Amantin District',
    'Banda District',
    'Berekum East Municipal District',
    'Berekum Municipal District',
    'Dormaa East District',
    'Dormaa Municipal District',
    'Dormaa West District',
    'Jaman North District',
    'Jaman South District',
    'Kintampo North Municipal District',
    'Kintampo South District',
    'Nkoranza North District',
    'Nkoranza South District',
    'Pru District',
    'Sene East District',
    'Sene West District',
    'Sunyani Municipal District',
    'Sunyani West District',
    'Tain District',
    'Tano North District',
    'Tano South District',
    'Techiman Municipal District',
    'Techiman North District',
    'Wenchi Municipal District',
    'Abura/Asebu/Kwamankese District',
    'Agona East District',
    'Agona West Municipal District',
    'Ajumako/Enyan/Essiam District',
    'Asikuma/Odoben/Brakwa District',
    'Assin North Municipal District',
    'Assin South District',
    'Awutu Senya East Municipal District',
    'Awutu Senya West District',
    'Cape Coast Metropolitan District',
    'Effutu Municipal District',
    'Ekumfi District',
    'Gomoa East District',
    'Gomoa West District',
    'Komenda/Edina/Eguafo/Abirem Municipal District',
    'Mfantsiman Municipal District',
    'Twifo-Ati Morkwa District',
    'Twifo/Heman/Lower Denkyira District',
    'Upper Denkyira East Municipal District',
    'Upper Denkyira West District',
    'Akuapim South District',
    'Akuapim North District',
    'Akyemansa District',
    'Asuogyaman District',
    'Ayensuano District',
    'Atiwa East District',
    'Atiwa West District',
    'Birim Central Municipal District',
    'Birim North District',
    'Birim South District',
    'Denkyembour District',
    'East Akim Municipal District',
    'Fanteakwa District',
    'Kwaebibirem District',
    'Kwahu Afram Plains North District',
    'Kwahu Afram Plains South District',
    'Kwahu East District',
    'Kwahu South District',
    'Kwahu West Municipal District',
    'Lower Manya Krobo District',
    'New-Juaben Municipal District',
    'Nsawam Adoagyire Municipal District',
    'Suhum Municipal',
    'Upper Manya Krobo District',
    'Upper West Akim District',
    'West Akim Municipal District',
    'Yilo Krobo Municipal',
    'Ablekuma North Municipal District',
    'Ablekuma West Municipal District',
    'Accra Metropolitan District',
    'Ada East District',
    'Ada West District',
    'Adenta Municipal District',
    'Ashaiman Municipal District',
    'Ayawaso East Municipal District',
    'Ayawaso North Municipal District',
    'Ayawaso West Municipal District',
    'Ga Central District',
    'Ga East Municipal District',
    'Ga North Municipal District',
    'Ga South Municipal District',
    'Ga West Municipal District',
    'Kpone Katamanso Municipal District',
    'Krowor Municipal District',
    'La Dade Kotopon Municipal District',
    'La Nkwantanang Madina Municipal District',
    'Ledzokuku Municipal District',
    'Ningo Prampram District',
    'Okaikwei Municipal District',
    'Shai Osudoku District',
    'Tema Metropolitan District',
    'Tema West Municipal District',
    'Weija Municipal District',
    'Bole District',
    'Bunkpurugu-Nyankpanduri District',
    'Central Gonja District',
    'Chereponi District',
    'East Gonja Municipal District',
    'East Mamprusi Municipal District',
    'Gushegu Municipal District',
    'Karaga District',
    'Kpandai District',
    'Kumbungu District',
    'Mamprugo Moaduri District',
    'Mion District',
    'Nanton District',
    'Nanumba North Municipal District',
    'Nanumba South District',
    'North Gonja District',
    'Saboba District',
    'Sagnarigu Municipal District',
    'Savelugu Municipal District',
    'Sawla-Tuna-Kalba District',
    'Tamale Metropolitan District',
    'Tatale Sangule District',
    'Tolon District',
    'West Gonja District',
    'West Mamprusi Municipal District',
    'Yendi Municipal District',
    'Yunyoo-Nasuan District',
    'Zabzugu District',
    'Bawku Municipal District',
    'Bawku West District',
    'Binduri District',
    'Bolgatanga Municipal District',
    'Bongo District',
    'Builsa District',
    'Builsa South District',
    'Garu-Tempane District',
    'Kassena Nankana East District',
    'Kassena Nankana West District',
    'Nabdam District',
    'Pusiga District',
    'Talensi District',
    'Daffiama Bussie Issa District',
    'Jirapa District',
    'Lambussie Karni District',
    'Lawra District',
    'Nadowli District',
    'Nandom District',
    'Sissala East District',
    'Sissala West District',
    'Wa East District',
    'Wa Municipal District',
    'Wa West District',
    'Adaklu District',
    'Afadjato South District',
    'Agotime Ziope District',
    'Akatsi North District',
    'Akatsi South District',
    'Biakoye District',
    'Central Tongu District',
    'Ho Municipal District',
    'Ho West District',
    'Hohoe Municipal District',
    'Jasikan District',
    'Kadjebi District',
    'Keta Municipal District',
    'Ketu North District',
    'Ketu South Municipal District',
    'Kpando Municipal District',
    'Krachi East District',
    'Krachi Nchumuru District',
    'Krachi West District',
    'Nkwanta North District',
    'Nkwanta South District',
    'North Dayi District',
    'North Tongu District',
    'South Dayi District',
    'South Tongu District',
    'Ahanta West District',
    'Aowin/Suaman District',
    'Bia West District',
    'Bia East District',
    'Bibiani/Anhwiaso/Bekwai District',
    'Bodi District',
    'Ellembele District',
    'Jomoro District',
    'Juaboso District',
    'Mpohor District',
    'Mpohor/Wassa East District',
    'Nzema East Municipal District',
    'Prestea-Huni Valley District',
    'Sefwi Akontombra District',
    'Sefwi Wiawso Municipal District',
    'Sekondi Takoradi Metropolitan Assembly',
    'Shama District',
    'Suaman District',
    'Tarkwa-Nsuaem Municipal District',
    'Wasa Amenfi East District',
    'Wasa Amenfi West District',
    'Wassa Amenfi Central District'
];

export default DISTRICTS;
