const UserActionTypes = {
    GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    SIGN_UP_START: 'SIGN_UP_START',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
    EDIT_USER_PROFILE_START: 'EDIT_USER_PROFILE_START',
    EDIT_USER_PROFILE_SUCCESS: 'EDIT_USER_PROFILE_SUCCESS',
    EDIT_USER_PROFILE_FAILURE: 'EDIT_USER_PROFILE_FAILURE',
    DELETE_USER_PROFILE_IMAGE_START: 'DELETE_USER_PROFILE_IMAGE_START',
    DELETE_USER_PROFILE_IMAGE_SUCCESS: 'DELETE_USER_PROFILE_IMAGE_SUCCESS',
    DELETE_USER_PROFILE_IMAGE_FAILURE: 'DELETE_USER_PROFILE_IMAGE_FAILURE',
    UPDATE_PROPERTY_PROFILE_IMAGES_START: 'UPDATE_PROPERTY_PROFILE_IMAGES_START',
    UPDATE_PROPERTY_PROFILE_IMAGES_SUCCESS: 'UPDATE_PROPERTY_PROFILE_IMAGES_SUCCESS',
    UPDATE_PROPERTY_PROFILE_IMAGES_FAILURE: 'UPDATE_PROPERTY_PROFILE_IMAGES_FAILURE'
};

export default UserActionTypes;
