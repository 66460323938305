const PropertyActionTypes = {
    FETCH_PROPERTIES_START: 'FETCH_PROPERTIES_START',
    FETCH_PROPERTIES_SUCCESS: 'FETCH_PROPERTIES_SUCCESS',
    FETCH_PROPERTIES_FAILURE: 'FETCH_PROPERTIES_FAILURE',
    REMOVE_PROPERTY_START: 'REMOVE_PROPERTY_START',
    REMOVE_PROPERTY_SUCCESS: 'REMOVE_PROPERTY_SUCCESS',
    REMOVE_PROPERTY_FAILURE: 'REMOVE_PROPERTY_FAILURE',
    DELETE_FILE_FROM_SERVER_SUCCESS: 'DELETE_FILE_FROM_SERVER_SUCCESS',
    DELETE_FILE_FROM_SERVER_FAILURE: 'DELETE_FILE_FROM_SERVER_FAILURE',
};

export default PropertyActionTypes;
