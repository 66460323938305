const REGIONS = [
    'Ashanti',
    'Bono',
    'Bono East',
    'Ahafo',
    'Central',
    'Eastern',
    'Greater Accra',
    'Northern',
    'Savannah',
    'North East',
    'Upper East',
    'Upper West',
    'Volta',
    'Oti',
    'Western',
    'Western North',
];

export default REGIONS;
