const PropertyUploadTypes = {
    PROPERTY_STORAGE_UPLOAD_START: 'PROPERTY_STORAGE_UPLOAD_START',
    PROPERTY_STORAGE_UPLOAD_SUCCESS: 'PROPERTY_STORAGE_UPLOAD_SUCCESS',
    PROPERTY_STORAGE_UPLOAD_FAILURE: 'PROPERTY_STORAGE_UPLOAD_FAILURE',
    PROPERTY_FIRESTORE_UPLOAD_START: 'PROPERTY_FIRESTORE_UPLOAD_START',
    PROPERTY_FIRESTORE_UPLOAD_SUCCESS: 'PROPERTY_FIRESTORE_UPLOAD_SUCCESS',
    PROPERTY_FIRESTORE_UPLOAD_FAILURE: 'PROPERTY_FIRESTORE_UPLOAD_FAILURE',
    PROPERTY_EDIT_START: 'PROPERTY_EDIT_START',
    PROPERTY_EDIT_SUCCESS: 'PROPERTY_EDIT_SUCCESS',
    PROPERTY_EDIT_FAILURE: 'PROPERTY_EDIT_FAILURE',
};

export default PropertyUploadTypes;
